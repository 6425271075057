@import (reference) '~@zola/zola-ui/src/styles/common/_layout';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables';

.footer {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;

  .mobile({
    width: 100%;
  });
}

.isPostAuthNav3 {
  .desktop({
    padding-left: 74px;
  });

  @media (min-width: 1920px) {
    margin-left: 0;
    max-width: 1920px;
    border-right: 1px solid @color-black-030;
    background-color: @color-white;

    > div {
      border-left: none;
      border-right: none;
    }

    footer {
      border-right: none !important;
      border-left: none !important;
    }
  }
}

.postAuthNav3PageWrapper {
  @media (min-width: 1920px) {
    background-color: @color-black-005;
  }
}
