@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';

.marketplace__suggestion {
  border: none;
  &:hover {
    cursor: pointer;
    background-color: @color-black-005;
  }
  .marketplace__suggestion-vendor {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }
  .marketplace__suggestion-vendor_icon {
    background-color: @color-black-005;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .marketplace__suggestion-vendor_name {
    .suggestion-text-main-regular();
  }
  .marketplace__suggestion-vendor_name_storefront {
    .suggestion-text-main();
  }
  .marketplace__suggestion-vendor_location {
    .suggestion-text-secondary();

    margin-top: 2px;
  }
}

.marketplace-autosuggest-vendor-field {
  .react-autosuggest__container {
    position: relative;
    .marketplace__suggestion-btn {
      .suggestion-btn();
    }
    .react-autosuggest__suggestions-container {
      .suggestions-container();

      &--open {
        background-color: @color-white;
        box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
        border: 1px solid @color-outline-gray;
        .marketplace__suggestion-btn {
          border: none;
          border-top: 1px solid @color-outline-gray;
        }
      }
    }
    .react-autosuggest__suggestions-list {
      max-height: 210px;
      overflow-y: auto;
      padding-left: 0;
      list-style-type: none;
      margin: 0;
    }
  }
}
.error-box {
  width: 100%;
  margin-top: 5px;
  color: @color-semantic-negative-100;
  font-size: @font-size-p-small;
}
