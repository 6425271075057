@import (reference) '~/stylesheets/core.less';

@toast-width: 380px;
@from-top: 220px;
@banner-offset: 40px;

.unrespondedMessageWrapper {
  .not-desktop({
    display: none;
  });

  :global {
    .fadeOut-exit {
      opacity: 1;
    }
    .fadeOut-exit-active {
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
  }
}

.unrespondedMessage {
  z-index: @zIndex-content-top;
  position: fixed;
  top: @from-top !important;
  // left: calc(100vw - @toast-width / 2) !important;
  text-align: center;
  width: @toast-width;
  text-transform: capitalize;
  right: @spacing-md;

  &.withBanner {
    top: @from-top + @banner-offset !important;
  }
}
