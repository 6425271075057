@import (reference) '~/stylesheets/core.less';

@jewel-size: 8px;

.jewel {
  display: inline-block;
  height: @jewel-size;
  width: @jewel-size;
  margin-left: 4px;
  background-color: @color-rose-100;
  border-radius: 50%;
}
