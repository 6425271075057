@import (reference) '~/stylesheets/core.less';
@import (reference) '../../VendorLayout.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

@active-menu-z-index: @nav-z-index + 2;

.navLink {
  display: flex;
  align-items: center;
  color: @color-white-100;
  font-size: @font-size-h5;
  text-decoration: none;
  line-height: @vendors-nav-height;

  :global {
    .nav-credit-balance {
      display: none;
    }
  }

  &:hover,
  &:focus {
    color: @color-cloud-100;
    text-decoration: none;
    opacity: 0.75;
  }

  &.activeNavLink {
    color: @color-spring-100;
    font-weight: @font-weight-h5;

    &:hover,
    &:focus {
      color: @color-cloud-100;
    }
  }
}

.loginBtn {
  color: #deeaff !important;
  background-color: @color-winter-100 !important;
  border: 1px solid #deeaff !important;

  &:hover {
    background-color: #deeaff !important;
    color: @color-winter-100 !important;
  }
}

.signupBtn {
  background-color: #deeaff !important;
  color: @color-winter-100 !important;
  margin-left: @spacing-xs;

  .mobile({
    display: none!important;
  });

  &:hover {
    color: #deeaff !important;
    background-color: @color-winter-100 !important;
    border: 1px solid #deeaff !important;
  }
}

.navItem {
  display: flex;
  align-items: center;

  .tag {
    margin-left: @spacing-xs;
    pointer-events: none;

    .not-desktop-xl({
      display:none;
    });
  }
}

.navDropdown {
  .navChevron {
    margin-left: 8px;
  }

  .subNav {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    padding: @spacing-sm @spacing-md;
    z-index: @active-menu-z-index;
    position: absolute;
    background-color: @color-white;
    top: @vendors-nav-height;

    .navLink {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      color: @color-black-100;
      line-height: 22px;
    }
  }
}
