@import (reference) '~/stylesheets/core.less';

@z-index-nav: 500;
@z-index-root: 20;

.navContainer {
  position: sticky;
  top: -0.25px; // Prevent a subtle gap between the nav and the top of the screen
  z-index: @z-index-nav;
  transform: none;
  pointer-events: none; // Prevent the nav container, which is taller than the root nav when the page is scrolled, from blocking clicks

  .hidden {
    display: none;
  }

  :global {
    #root-nav {
      width: 100%;
      z-index: @z-index-root;
      pointer-events: auto;
    }
  }

  &.postAuth {
    :global {
      #root-nav {
        max-width: 1920px;

        @media (min-width: 1920px) {
          border-right: 1px solid @color-black-030;
        }
      }
    }
  }
}
