// stylelint-disable no-descending-specificity
@import (reference) '~/stylesheets/core.less';
@import (reference) '../../VendorLayout.less';

@dropdown-width: 300px;
@dropdown-z-index: 30;
@image-width: 44px;
@scrollbar-background-color: rgba(0, 0, 0, 0.5);
// The height should leave room for the nav and bottom section of the menu
@storefront-list-max-height: calc(100vh - @vendors-nav-height - 360px);

.accountDropdownWrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: @vendors-nav-height;

  &:hover {
    .dropdownMenu {
      display: block;
      padding: 12px 24px;
    }
  }
}

.accountDropdown {
  .image {
    width: @image-width;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    white-space: nowrap;
    overflow: hidden;

    .not-desktop-xl({
      display: none;
    });
  }

  .fullName {
    color: @color-white-100;
    font-size: @zui-fontsize-smaller;
  }

  .storefrontName {
    color: @color-spring-100;
    font-size: @zui-fontsize-extra-small;
  }

  .fullName,
  .storefrontName {
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: @font-weight-regular;
  }

  .caret {
    color: @color-white-100;
  }

  .link {
    display: flex;
    align-items: center;
    gap: 12px;
    text-transform: none;
    text-decoration: none;

    &:hover {
      .fullName,
      .storefrontName,
      .caret {
        color: @color-cloud-100;
        opacity: 0.75;
      }
    }
  }

  .storefrontList {
    max-height: @storefront-list-max-height;
    margin: -12px -24px -8px; // Align the scrollbar with the edge of the dropdown
    padding: 12px 24px 8px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      appearance: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid @color-white-100; // Should match background, not transparent
      background-color: @scrollbar-background-color;
    }

    &::-webkit-scrollbar-track {
      background-color: @color-white-100;
      border-radius: 8px;
    }

    &::-webkit-scrollbar:vertical {
      width: 8px;
    }

    &::-webkit-scrollbar:horizontal {
      width: 8px;
    }
  }

  .dropdownMenu {
    user-select: none;
    position: absolute;
    display: none;
    top: @vendors-nav-height;
    right: -16px;
    max-height: calc(100vh - @vendors-nav-height);
    background-color: @color-white-100;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: @dropdown-z-index;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-bottom: 8px;

    .dropdownHeader {
      padding: 12px 0;
      color: @color-black-075;
      font-size: @zui-fontsize-small;
      font-weight: @font-weight-semi-bold;
    }

    .dropdownDivider {
      margin: 8px 0;
      border-bottom: 1px solid @color-black-030;
    }

    .dropdownItem {
      padding-top: 12px;
      padding-bottom: 12px;

      .link {
        display: block;
        padding: 0;
        color: @color-black-100;
        font-size: @font-size-h5;
        font-size: @zui-fontsize-smaller;
        white-space: nowrap;
        text-decoration: none;

        &.storefront {
          display: flex;
          align-items: center;
          gap: 12px;
          color: @color-black-030;
          font-size: @zui-fontsize-small;

          .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
          }

          .linkImage {
            width: @image-width;
            opacity: 0.5;
          }

          .linkName {
            color: @color-black-050;
            font-weight: @font-weight-regular;
            white-space: nowrap;
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              .linkImage {
                opacity: 1;
              }
            }
          }

          .creditBalance {
            color: @color-black-050;
            font-weight: @font-weight-regular;
            font-size: @zui-fontsize-extra-small;
            text-transform: none;
          }

          &.active {
            color: @color-black-100;
            font-weight: @font-weight-semibold;

            .linkName {
              color: @color-black-100;
            }

            .linkImage {
              opacity: 1;
            }
          }

          &:hover {
            color: @color-black-075;

            .linkName {
              color: @color-black-075;
            }

            .linkImage {
              opacity: 1;
            }
          }
        }

        &.addStorefront {
          color: @color-black-100;
          font-weight: @font-weight-semi-bold;
        }

        &:hover {
          color: @color-black-075;
        }
      }

      .logout {
        margin-top: 16px;
      }
    }
  }
}
