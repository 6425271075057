@import (reference) '~/stylesheets/core.less';
@import (reference) '../../VendorLayout.less';

@hamburger-width: 28px;
@dropdown-z-index: @nav-z-index + 1;

.mobileNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: @vendors-nav-height;
  padding-left: 20px;
  padding-right: 20px;
  background-color: @color-winter-100;

  .desktop({
    display: none;
  });
}

.storefrontMenu {
  display: flex;
  align-items: center;
  gap: 12px;

  &.preAuthMenu {
    visibility: hidden;
  }

  svg {
    color: @color-white-100;
  }

  :global {
    .marketplace__circle-image {
      width: 44px;
      height: 44px;
      background-color: @color-winter-100;

      .not-desktop({ display: none; });
    }
  }
}

.storefrontMenuSpacer {
  width: @hamburger-width;
}

li.navSeparator {
  hr {
    margin: 8px 0;
  }
}

li.navHeading {
  padding-left: 20px;
  color: @color-black-075;
  font-weight: @font-weight-semi-bold;
  font-size: @zui-fontsize-small;
  height: 56px;
  display: flex;
  align-items: center;
}

.storefrontLink {
  color: @color-black-050;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  .storefrontImage {
    height: 44px;
    width: 44px;
    flex-shrink: 0;
  }
}

.storefrontInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.storefrontName {
  font-weight: @font-weight-semi-bold;
}

.storefrontCreditBalance {
  font-weight: @font-weight-regular;
}

.navLink {
  display: inline-block;
  padding: 20px;
  color: @color-black-075;
  font-size: @zui-fontsize-regular;
  font-weight: @font-weight-semi-bold;
  vertical-align: middle;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      color: @color-black-075;
      text-decoration: none;
    }
  }

  &.newStorefront {
    color: @color-bay-100;
    font-weight: @font-weight-semi-bold;
  }

  :global {
    .nav-credit-balance {
      font-size: @zui-fontsize-small;
      color: @color-black-075;
      font-weight: @font-weight-regular;

      &:hover {
        color: @color-black-075;
      }
    }

    .tag,
    .nav-tag {
      margin-left: 4px;
    }
  }
}

.activeNavLink {
  color: @color-black-100;
  font-weight: @font-weight-semi-bold;

  @media (hover: hover) {
    &:hover {
      color: @color-black-075;
    }
  }

  .storefrontLink {
    color: @color-black-100;
  }
}

.navTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .navLink {
    padding-left: 0;
  }

  .navChevron {
    transform: rotate(0deg);
  }
}

.activeNavTitle {
  .navLink {
    color: @color-black-075;
  }

  .navChevron {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
  }
}

// Below this width, we'll display Zola Vendors instead of Zola For Vendors
@max-script-width: 420px;

.vendorLogo {
  display: flex;
  align-items: center;

  svg {
    width: 120px !important;
  }
}

li.navItem {
  display: block;
}

.subNav {
  padding-inline-start: 0 !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin: 0;
  padding: 0 !important;
  background-color: @color-cloud-100;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  border-top: 0 solid @color-black-030;
  border-bottom: 0 solid @color-black-030;
  line-height: initial;

  &.activeSubNav {
    height: auto;
    // Let the nav grow as big as it needs to
    max-height: 1000px;
    padding: 8px 0 !important;
    overflow-y: auto;
    border-top-width: 1px;
    border-bottom-width: 1px;
    .conceal-scrollbars();
  }

  .navLink {
    font-weight: @font-weight-regular;
    font-size: @zui-fontsize-small;
    padding-left: 20px;

    &.newStorefront {
      font-weight: @font-weight-semi-bold;
    }
  }

  .activeNavLink {
    font-weight: @font-weight-semi-bold;
  }

  .navItem {
    border: none;
  }
}

.hamburgerMenu {
  position: relative;
  display: inline-block;
  width: @hamburger-width;
  height: @hamburger-width;
  margin: 20px 0;
  padding: 0;
  border: 0;
  background: 0;

  :global {
    span {
      position: absolute;
      display: block;
      top: 50%;
      width: 100%;
      height: 2px;
      border-radius: 9px;
      background-color: @color-white-100;
      opacity: 1;
      transition: all 0.5s ease;

      &:first-child {
        top: 22%;
      }

      &:last-child {
        top: 78%;
      }
    }
  }

  &.activeHamburger {
    :global {
      span {
        &:first-child,
        &:last-child {
          top: 50%;
          width: 0;
          margin-left: 20px;
          margin-right: 20px;
          opacity: 0;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.dropdownMenu {
  position: absolute;
  display: block;
  top: @vendors-nav-height;
  left: 0;
  right: 0;
  max-height: 0;
  background-color: @color-white-100;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  overflow: auto;
  .conceal-scrollbars();

  transition: all 0.5s ease-in-out;
  z-index: @dropdown-z-index;
}

li.login {
  padding: 20px;
}

li.logout {
  padding: 20px;
  background-color: @color-cloud-100;
}

.storefrontDropdownMenu {
  .subNav {
    background-color: @color-white-100;
  }

  li.logout {
    border-bottom: none;
  }
}

.navItems {
  display: block;
}

.navButton {
  width: 100%;
}
