@import (reference) '~@zola/zola-ui/src/common.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

@z-index-heart: 1;

.button {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  .mobile({
    padding: 0 @spacing-xs !important;
  });
  .photoHeartFaved {
    background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-full-white-outline.svg');
  }
  .photoHeartFavedLg {
    background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-full-white-outline-lg.svg');
  }
  .photoHeartUnfaved {
    background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-empty-white-outline.svg');
  }
  .photoHeartUnfavedLg {
    background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-empty-white-outline-lg.svg');
  }
  .vendorHeartFaved {
    background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-full-no-outline.svg');
  }
  .vendorHeartUnfaved {
    background-image: url('../assets/heart-empty-black-outline.svg');
  }
  .heart {
    width: 44px;
    height: 44px;
    z-index: @z-index-heart;
    .mobile({
      width: 50px;
      height: 50px;
    });
  }

  &.buttonCompact {
    .heart {
      &:hover.photoHeartUnfaved {
        background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-empty-pink-outline.svg');
      }
      &:hover.vendorHeartUnfaved {
        background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-empty-pink-outline.svg');
      }
      &:hover.photoHeartUnfavedLg {
        background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-empty-pink-outline-lg.svg');
        width: 41px;
      }
    }
  }

  &.buttonCircle {
    width: 44px;
    height: 44px;
    padding: 0 !important;
    border-radius: 50%;

    // default state
    background-color: @color-background-white !important;
    .heart {
      background-image: url('../assets/gray-heart-full-no-outline.svg');
      .mobile({
        width: 44px !important;
        height: 44px !important;
      });
    }

    // favorited state
    &.selected {
      background-color: @color-background-white !important;
      .heart {
        background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-full-no-outline.svg');
      }
    }

    // hover state
    &:hover {
      background-color: @color-background-melon !important;
      .heart {
        background-image: url('../assets/white-heart-full-no-outline.svg');
      }
    }
  }

  &.buttonCircleOutline {
    border: 1px solid @color-text-gray !important;
    height: 44px;
    padding: 0 !important;
    border-radius: 50%;

    > div {
      height: 44px;
      width: 43px;
    }
    @media (hover: hover) {
      &:hover {
        background-color: @color-melon-variant-12;
        border-color: @color-melon-variant-12 !important;
      }
    }
    &:active {
      background-color: @color-melon-variant-12;
      border-color: @color-melon-variant-12 !important;
    }
  }
}

button.button.buttonFull {
  // default state
  background-color: @color-background-white;
  color: @color-emperor;
  /* stylelint-disable-next-line no-descending-specificity */
  .heart {
    background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-empty-dark-outline.svg');
  }

  // favorited state
  &.selected {
    color: @color-melon;
    .heart {
      background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-full-no-outline.svg');
    }
  }

  // hover state
  &:hover {
    background-color: @color-background-melon;
    color: @color-white;
    .heart {
      background-image: url('../assets/white-heart-full-no-outline.svg');
    }
  }
}

button.button.circleZui {
  border: 1px solid @color-text-gray !important;
  height: 44px;
  padding: 0 !important;
  border-radius: 50%;

  /* stylelint-disable-next-line no-descending-specificity */
  .heart {
    background-image: url('../assets/heart-empty-black-outline.svg');

    &.favorited {
      background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-full-no-outline.svg');
    }
  }

  &:hover {
    .heart {
      background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-empty-pink-outline.svg');
    }
  }
}

button.button.circleZuiV3 {
  border: 1px solid @color-text-gray !important;
  height: 44px;
  padding: 0 !important;
  border-radius: 50%;

  /* stylelint-disable-next-line no-descending-specificity */
  .heart {
    background-image: url('../assets/heart-empty-black-outline.svg');
    background-size: 32px 32px;
    background-position: 50% 55%;
    background-repeat: no-repeat;

    &.favorited {
      background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-full-no-outline.svg');
    }
  }

  &:hover {
    .heart {
      background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-empty-pink-outline.svg');
    }
  }
}

@keyframes pulse {
  0% {
    transform: none;
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: none;
  }
}

button.button.v3 {
  height: 40px;

  /* stylelint-disable-next-line no-descending-specificity */
  .heart {
    background-image: url('../assets/v3-unfavorited.svg');
    background-size: 40px 40px;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    &.favorited {
      background-image: url('../assets/v3-favorited.svg');
    }

    &.animate {
      animation: pulse 0.5s 1;
    }
  }

  &:hover {
    .heart {
      background-image: url('../assets/v3-favorited.svg');
    }
  }
}

.heartIcon {
  margin-right: @spacing-xs;

  &.isFavorited {
    color: @color-rose-100;
  }
}
