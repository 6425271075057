@import (reference) '~/stylesheets/core.less';

// Should always match VENDOR_NAV_HEIGHT from components/common/layouts/VendorLayout/VendorLayout.styles.ts
@vendors-nav-height: 70px;

// Should always match VENDOR_NAV_Z_INDEX from components/common/layouts/VendorLayout/VendorLayout.styles.ts
@nav-z-index: 21;

.marketplace__vendors {
  min-height: 80vh;
  margin-top: @vendors-nav-height;
  // This is necessary for the dashboard marketing modules and lead details UX to render correctly on mobile
  overflow-x: hidden;

  // instances of zola-ui ModalV2 that use the `lockBgScrolling` need this
  &.modalV2-backdrop {
    width: 100vw;
  }
}

.marketplace__vendors-footer {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
