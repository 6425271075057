@import (reference) '~/stylesheets/core.less';
@import (reference) '../VendorLayout.less';

@overlay-z-index: @nav-z-index - 2;

.vendorsNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: @vendors-nav-height;
  background-color: @color-winter-100;
  z-index: @nav-z-index;

  .desktop-lg({
    height: @vendors-nav-height;
  });
}

.overlay {
  position: absolute;
  transition: background-color 0.1s ease-in-out 0.05s;
}

.active {
  top: @vendors-nav-height;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(48, 48, 48, 0.3);
  z-index: @overlay-z-index;
}
