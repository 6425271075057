@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';
@import '~/stylesheets/util/_type.module.less';

.marketplaceSuggestion {
  padding: 5px @spacing-md;
  border: none;
  &.inline {
    display: flex;
    align-items: baseline;
  }
  &:hover {
    cursor: pointer;
    background-color: @color-black-005;
  }
  &.active {
    background-color: @color-black-005;
  }
  .textMain {
    .suggestion-text-main();
  }
  .textSecondary {
    .suggestion-text-secondary();

    margin-left: 5px;
    text-transform: capitalize;
  }
}

.label {
  margin-top: @spacing-lg;
  color: @color-black;
}

.marketplaceAutosuggestLocationField {
  :global {
    .react-autosuggest__container {
      position: relative;
      .marketplace__suggestion-btn {
        .suggestion-btn();
      }
      .react-autosuggest__suggestions-container {
        .suggestions-container();

        &--open {
          background-color: @color-white;
          box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
          border: 1px solid @color-outline-gray;
          .marketplace__suggestion-btn {
            border: none;
            border-top: 1px solid @color-outline-gray;
          }
        }
      }
      .react-autosuggest__suggestions-list {
        max-height: 210px;
        overflow-y: auto;
        padding-left: 0;
        list-style-type: none;
        margin: 0;
      }
    }
    #locationNameSearch.with-icon {
      padding-left: @spacing-lg;
    }
    .input-addon {
      width: @spacing-md;
      height: @spacing-md;
      position: absolute;
      bottom: 28%;
      left: 14px;
    }
  }
}
