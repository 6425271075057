@import (reference) '~/stylesheets/core.less';

.marketplace__feedback-widget {
  background-color: @color-secondary-background-gray;
  padding: 10px 10px 10px 40px;
  position: fixed;
  z-index: 5;
  right: 0;
  bottom: 0;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  .feedback-widget__icon {
    position: absolute;
    left: -10px;
    top: -10px;
    height: 130%;
  }
  .feedback-widget__heading {
    font-weight: @font-weight-bold;
  }
  .feedback-widget__more-info {
    font-size: @font-size-p-small;
    display: none;
  }
  &:hover {
    padding-left: 70px;
    .feedback-widget__icon {
      left: -20px;
      top: -20px;
    }
    .feedback-widget__more-info {
      display: block;
    }
  }
}
