@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) './fonts3.module.less';

.letter-spacing-percent(@size, @spacing) {
  letter-spacing: calc(@spacing * @size / 100);
}
.font-size(@size, @spacing) {
  font-size: @size;
  .letter-spacing-percent(@size, @spacing);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  &.heading {
    margin: 0;
    padding: 0;
    color: @color-black-100;
    font-weight: @font-weight-regular;
    font-family: @font-family-circular;
    line-height: @headings-line-height;

    &.strong {
      font-weight: @font-weight-medium;
      font-family: @font-family-new-spirit;
    }
  }

  &.h1,
  &.h2 {
    line-height: 100%;
  }

  &.h1 {
    // 64/64, -4%
    .font-size(64px, -4);

    .mobile({
      // 42/42, -4%
      .font-size(42px, -4);            
    });
  }

  &.h2 {
    // 48/48, -4%
    .font-size(48px, -4);

    .mobile({
      // 32/32, -1.3px
      font-size: @font-size-larger;
      letter-spacing: -1.3px;
    });
  }

  &.h3 {
    // 32/40, -4%
    .font-size(@font-size-larger, -4);

    line-height: 40px;

    .mobile({
      // 24/30, -1.2px
      .font-size(@font-size-large, -4);
      line-height: 30px;
      letter-spacing: -1.2px;
    });
  }

  &.h4 {
    // 24/32, -3%
    .font-size(@font-size-large, -3);

    line-height: 32px;

    .mobile({
      // 20/26, -1.2px
      font-size: @font-size-regular;
      line-height: 26px;
      letter-spacing: -1.2px;
    });
  }

  &.h5 {
    // 20/24, -2%
    .font-size(@font-size-regular, 0);

    line-height: 24px;

    .mobile({
      .font-size(18px, 0);

      line-height: 23px;
    });
  }

  &.h6 {
    // 16/20,- 1%
    .font-size(@font-size-small, 0);

    line-height: 20px;

    .mobile({
      line-height: 21px;
    });
  }
}
