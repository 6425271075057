@import (reference) '~/stylesheets/core.less';
@import (reference) '../../VendorLayout.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins';

.desktopNav {
  height: @vendors-nav-height;
  padding-left: @spacing-lg;
  padding-right: @spacing-lg;
  background-color: @color-winter-100;
  display: flex;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;

  .mobile({
    padding-left: @spacing-24;
    padding-right: @spacing-24;
  });

  &.hideNonDesktop {
    .not-desktop({
      display: none;
    });
  }
}

.navLeft {
  flex: 1;
}

.navRight {
  display: block;
}

.navLogoLink {
  height: @vendors-nav-height;
  display: flex;
  align-items: center;

  svg {
    width: 120px !important;
  }
}

.navItems {
  display: flex;
  flex: 1;
  justify-content: center;
  gap: 32px;

  // Remove this when the enableVendorInboxV3 feature flag is removed
  @media (max-width: 1366px) {
    gap: 24px;
  }
}

.navItem {
  display: inline-block;
  min-height: @vendors-nav-height;
  vertical-align: middle !important;
}
