@import (reference) '~/stylesheets/core.less';

.marketplace__circle-image {
  width: 100%;
  background-color: @color-secondary-background-gray;
  border-radius: 50%;
  overflow: hidden;

  .circle-image__image {
    width: 100%;
    padding-top: 100%;
    background-size: 100% 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
