@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/util/_type.module.less';

.modalContent {
  max-width: 600px;
  padding: @spacing-lg;

  .mobile({
 padding: @spacing-24;     
 });

  .header {
    margin-bottom: @spacing-8;
  }
  .dek {
    margin-bottom: @spacing-24;
  }

  .checkbox {
    margin-top: @spacing-16;

    :global {
      .selector-label {
        font-size: 14px;
        color: #4a4a4a;
      }
    }
  }
  .modalControls {
    width: 100%;

    .cancelAndSave {
      display: flex;
      justify-content: flex-end;
      margin-top: @spacing-16;

      .mobile({
        margin-bottom: 80px;
      });

      .cancel {
        margin-right: @spacing-sm;

        .mobile ({
          width: 60%;
        });;;
      }
    }
  }
}
