@import (reference) '~/stylesheets/core.less';

.strikeThrough {
  text-decoration: line-through;
  color: @color-messaging-red;
  text-decoration-thickness: 2px;

  .baseCreditPrice {
    color: @color-text-gray;
  }
}

.discountText {
  text-decoration: none;
  margin-left: @spacing-xxs;
}
