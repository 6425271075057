@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~/stylesheets//util/_type.module.less';

.claimCreditsModal,
.creditsRedeemedModal {
  padding: @spacing-lg;
  max-width: 560px;

  .mobile({
    padding:  @spacing-md;
  });

  .hed {
    .large-heavy();

    user-select: none;
  }
  .dek {
    margin-top: @spacing-md;
    margin-bottom: @spacing-md;

    .bold {
      font-weight: @font-weight-semi-bold;
    }
  }
  .controls {
    display: flex;
    gap: 20px;

    .not-mobile({
      justify-content: flex-end;
    });

    .mobile({
      flex-direction: column;
    });
  }
}
